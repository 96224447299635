<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" v-if="reMountedForm">
      <a-form-model-item label="设备编号" prop="deviceId">
        <a-input v-model="form.deviceId" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="设备时间" prop="deviceTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.deviceTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="当前状态的sim卡号" prop="simNo">
        <a-input v-model="form.simNo" placeholder="请输入当前状态的sim卡号" />
      </a-form-model-item>
      <a-form-model-item label="设备状态" prop="type">
        <a-select placeholder="请选择当前设备状态" v-model="form.type">
          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="GPS经度" prop="longitude">
        <a-input v-model="form.longitude" placeholder="请输入GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="GPS纬度" prop="latitude">
        <a-input v-model="form.latitude" placeholder="请输入GPS纬度" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAttendanceStatus, addAttendanceStatus, updateAttendanceStatus } from '@/api/jt808/attendanceStatus'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {
      reMountedForm: true,
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        deviceId: null,
        deviceTime: null,
        simNo: null,
        longitude: null,
        type: undefined,
        latitude: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
        deviceTime: [{ required: true, message: '设备时间不能为空', trigger: 'change' }],
        simNo: [{ required: true, message: '当前状态的sim卡号不能为空', trigger: 'blur' }],
        longitude: [{ required: true, message: 'GPS经度不能为空', trigger: 'blur' }],
        latitude: [{ required: true, message: 'GPS纬度不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {
      if (val) {
        this.reMountedForm = false
        this.$nextTick(() => {
          this.reMountedForm = true
        })
      }
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        deviceId: null,
        deviceTime: null,
        simNo: null,
        type: undefined,
        longitude: null,
        latitude: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAttendanceStatus(id).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            console.log('key', key)
            this.form[key] = this.form[key] + ''
          }
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.form.longitude = Number(this.form.longitude)
      this.form.latitude = Number(this.form.latitude)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.type = Number(this.form.type)
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAttendanceStatus(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addAttendanceStatus(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
