/*
 * @Auth: linjituan
 * @Date: 2021-11-15 15:34:25
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-24 17:24:05
 */
import request from '@/utils/request'
const baseURL = '/jt-api'
// 查询考勤记录列表
export function listAttendanceStatus (query) {
  return request({
    url: '/attendanceStatus/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询考勤记录详细
export function getAttendanceStatus (id) {
  return request({
    url: '/attendanceStatus/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增考勤记录
export function addAttendanceStatus (data) {
  return request({
    url: '/attendanceStatus',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改考勤记录
export function updateAttendanceStatus (data) {
  return request({
    url: '/attendanceStatus',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除考勤记录
export function delAttendanceStatus (id) {
  return request({
    url: '/attendanceStatus/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出考勤记录
export function exportAttendanceStatus (query) {
  return request({
    url: '/attendanceStatus/export',
    baseURL,
    method: 'get',
    params: query
  })
}
